h1 .alt {
  opacity: 0.6;
}

a {
  transition: 0.1s linear;
}

h1 span {
  transition: 0.1s linear;
}

h1 a {
  color: inherit;
  text-decoration: none;
}

h1 a:hover span {
  opacity: 0.6;
}

h1 a:hover span.alt {
  opacity: 1;
}

.light {
  color: white;
}

body {
  font-family: graphie, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #555;
}

.logo {
  background-color: rgba(0, 0, 0, 0.65);
  margin-left: -30px;
  padding: 5px 30px 5px 30px;
  display: inline-block;
}

.hero {
  background-color: #eee;
  padding: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  position: relative;
}

.hero-mimesis {
  background-image: url(https://i.imgur.com/HHMjiCH.jpg);
}

.hero-index {
  background-image: url(https://i.imgur.com/7ZBLdgl.jpg);
  background-position: center 0;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  opacity: 0.9;

  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.phase {
  position: absolute;
  font-size: 18px;
  bottom: 0;
  letter-spacing: 1px;
  right: 0;
  padding: 10px;
  background-color: rgba(208, 122, 15, 0.45);
}

.subheader {
  margin-top: -15px;
  line-height: 1.3em;
}

h3 {
  font-weight: normal;
  letter-spacing: 1px;
  opacity: 0.7;
  line-height: 1.2em;
}

.hero-text h3 {
  font-size: 30px;
}

.exhibit-wrapper {
  padding: 30px;
}

.exhibit-header {
  font-size: 50px;
  margin-bottom: 1.5em;
  margin-left: 10vw;
  margin-right: 10vw;
}

.frame-wrapper {
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #dfdfdf;
  display: inline-block;
}
/* inspired by https://codepen.io/chris22smith/pen/PbBwjp */
.frame {
  background-color: #100e0f;
  border: solid 5vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  /*   background-color: #8a7a52;
  border: solid 5vmin #6c4a18;
  border-bottom-color: #e5c780;
  border-left-color: #9a6c29;
  border-radius: 2px;
  border-right-color: #9a6c29;
  border-top-color: #ce9739; */

  box-shadow: 0px 3px 3px 2px rgb(0 0 0 / 15%), 0 5px 5px 3px rgb(0 0 0 / 25%);
  box-sizing: border-box;
  display: inline-block;
  padding: 10px;
  position: relative;
  text-align: center;
}
.frame:before {
  border-radius: 2px;
  bottom: -2vmin;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
  content: "";
  left: -2vmin;
  position: absolute;
  right: -2vmin;
  top: -2vmin;
  pointer-events: none;
}
.frame:after {
  border-radius: 2px;
  bottom: -2.5vmin;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  content: "";
  left: -2.5vmin;
  position: absolute;
  right: -2.5vmin;
  top: -2.5vmin;
  pointer-events: none;
}

.placard {
  background-color: #111;
  color: #efefef;
  padding: 40px;
  letter-spacing: 1px;
  font-size: 24px;
  width: 600px;
  margin-left: 20px;
  margin-right: 20px;
}

.placard .title {
  font-size: 1em;
  font-weight: 600;
  margin: 10px 0px 10px;
}

.placard a {
  color: inherit;
}

.placard .subtitle {
  font-size: 0.8em;
  font-style: italic;
  margin: 10px 0px;
}

.placard .description {
  font-size: 0.7em;
  margin-top: 25px;
  line-height: 1.3em;
}

.piece {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 80px;
}
.piece.flip {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 960px) {
  .piece,
  .piece.flip {
    flex-direction: column;
    align-items: center;
  }

  .placard {
    width: 80%;
    margin: 20px 0 0 0;
  }

  .exhibit-header {
    font-size: 30px;
  }

  .frame iframe {
    width: 80vw !important;
  }
}

.footer {
  background-color: #111;
  color: white;
  letter-spacing: 1px;
  font-size: 22px;
  padding: 100px 30px;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer .social-list {
  margin: 0;
  padding: 0;
}
.footer .social-list li {
  list-style: none;
  margin-bottom: 10px;
}

.exhibition-link {
  padding: 30px;
  font-size: 22px;
}

.exhibition-link .title {
  font-size: 40px;
  margin-bottom: 10px;
  font-style: italic;
}

a.exhibition-link {
  color: inherit;
  text-decoration: none;
  display: block;

  color: white;
  background-color: #333;
}

a.exhibition-link:hover {
  background-color: #ccc;
  color: #111;
}
